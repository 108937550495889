import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import ArchiveStyles from "./archives.module.css"
import SEO from "../components/seo";

const Blogs = ({ pageContext, data }) => {
  const { edges } = data.allMarkdownRemark

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()

  return (
    <Layout>
      <SEO title={"Blog"}></SEO>
      <h1>All posts</h1>
      <ul className={ArchiveStyles.postList}>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date, categories } = node.frontmatter
          const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid
          return (
            <li>
              <article>
                <Link to={slug}><Img className={ArchiveStyles.image} fluid={featuredImgFluid} alt={title} /></Link>
                <span className={ArchiveStyles.meta}>{date} - <Link to={"/"} className={ArchiveStyles.cat}>{categories}</Link></span>
                <h3><Link to={slug}>{title}</Link></h3>
                <p className={ArchiveStyles.intro}>{node.excerpt}</p>
                <Link to={slug} className={ArchiveStyles.readmore}>Read more</Link>
              </article>
            </li>
          )
        })}
      </ul>
      <div className={ArchiveStyles.pagination}>
        {!isFirst && (
          <Link to={prevPage} rel="prev" className={ArchiveStyles.prev}>
            ← Previous Page
          </Link>
        )}
        {!isLast && (
          <Link to={nextPage} rel="next" className={ArchiveStyles.next}>
            Next Page →
          </Link>
        )}
      </div>
    </Layout>
  )
}

Blogs.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              categories: PropTypes.array.isRequired,
              featuredImage: PropTypes.element.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
            excerpt: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Blogs

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!){
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/yy")
            title
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`